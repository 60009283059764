
.product-category__inner {
    transform: translateY(-40px);
}
::v-deep {
    .product-category-list {
        padding: 0;
        color: var(--v-grey-darken4) !important;

        .v-list-item {
            display: flex;
            align-items: center;
            // min-height: 26px;
            color: var(--v-grey-base) !important;
            &,
            &__title {
                font-size: var(--txt-font-size);
            }
            &__title {
                order: 0;
            }
            &,
            &__content {
                padding: 0 !important;
            }

            // item icon
            .v-icon {
                font-size: 18px;
                color: inherit;
                text-decoration: none;
            }
        }

        // group
        .v-list-group {
            // group icon
            &__header__prepend-icon {
                min-width: auto;
                .mdi-menu-down::before {
                    content: "\F0140";
                }
            }
            &__header__append-icon {
                min-width: auto;
            }

            // group items
            &__items {
                // padding-left: 6px !important;
                .v-list-item {
                    &:not(.v-list-item--active):not(.v-list-item--disabled) {
                        color: var(--v-grey-base) !important;
                    }
                    &--exact-active {
                        color: var(--v-grey-darken4) !important;
                        > .v-list-item__content,
                        .v-list-item__title {
                            text-decoration: underline;
                        }
                        .v-icon {
                            text-decoration: none !important;
                        }
                    }
                }
            }

            // group header title
            &__header {
                .v-list-item__title {
                    font-size: var(--txt-font-size-lg);
                }
            } 

            // sub-group
            .v-list-group--sub-group {
                .v-list-item__icon:first-child {
                    order: 1;
                    margin: 0;
                    margin-top: 4px;
                }
            }
        }

        // 첫번째 item
        > .v-list-item,
        > .v-list-group > .v-list-item {
            min-height: 48px;
            font-weight: 700;
            color: var(--v-grey-darken4) !important;
            // &--exact-active {
            //     color: var(--v-primary-base) !important;
            // }
            // &:not(.product-category-list--active) {
            //     color: var(--v-grey-darken4) !important;
            // }
            &,
            .product-category-list {
                &--active {
                    // color: var(--v-primary-base) !important;
                    > .v-list-item__content,
                    .v-list-item__title {
                        text-decoration: none !important;
                    }
                }
            }
        }
        > .v-list-group {
            > .v-list-group__items {
                margin-top: -4px;
                margin-bottom: 4px;
                // 두번째 item
                > .v-list-item,
                > .v-list-group > .v-list-item {
                    &--exact-active,
                    &.product-category-list--active {
                        > .v-list-item__content,
                        .v-list-item__title {
                            font-weight: 500;
                            text-decoration: none;
                            color: var(--v-primary-base) !important;
                        }
                    }
                }
            }
        }

        .product-category-list {
            &--active {
                > .v-list-item__content,
                .v-list-item__title {
                    text-decoration: underline;
                }
                .v-icon {
                    text-decoration: none !important;
                }
            }
        }
    }
}
