
.v-tab {
    height: fit-content;
    border-right: 1px solid var(--border-color);
    font-size: var(--txt-font-size-sm);
    &::before,
    &::after {
        display: none;
    }
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: none;
    }
    &--active {
        font-weight: 700;
    }
}
