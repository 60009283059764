
::v-deep {
    .v-item-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        .v-btn,
        .v-tab {
            min-width: auto !important;
            height: 50px !important;
            font-size: 14px !important;
            margin: var(--grid-gutter-xxs);
            color: var(--border-color-dark);
            border: 1px solid !important;
            &--active {
                color: #fff;
            }
        }
    }
    .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
        border-color: var(--v-primary-base) !important;
    }
}
