
::v-deep {
    .v-radio {
        &.v-item--active {
            .v-label {
                color: var(--v-grey-darken4);
            }
        }
    }
    .v-list-item {
        padding: 0;
        &__title {
            font-weight: bold;
        }
    }
    .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
        border-color: var(--v-primary-base) !important;
    }
    .v-btn-toggle {
        display: flex;
        justify-content: space-between;
        .v-btn {
            width: calc(50% - 4px);
            background-color: #fff !important;
            border-width: 1px !important;
            opacity: 1 !important;
            &__content {
                color: var(--v-primary-base);
            }
            &::before {
                display: none;
            }
        }
        .v-item--active {
            background-color: var(--v-primary-base) !important;
            .v-btn__content {
                color: #fff;
            }
        }
    }
    .v-input--selection-controls__input {
        margin-right: 0;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded).v-input--dense>.v-input__control>.v-input__slot {
        padding: 0 8px;
    }
    .v-slider__thumb::before {
        display: none;
    }
}
